// Возвращает массив из всех получасовых временных слотов от 00:00 до 23:30
const buildAllEverydaySlots = () => {
    const hoursPadded = Array.from(Array(24).keys()).map(hour => hour.toString().padStart(2, 0))
    return hoursPadded.map(hour => [`${hour}:00`, `${hour}:30`]).flat()
}

// Возвращает date + days дней
const addDays = (date, days) => {
    var newDate = new Date(date.valueOf())
    newDate.setDate(date.getDate() + days)
    return newDate
}

// Возвращает массив дней
const buildDays = (fromDatetime, toDatetime) => {
    let days = [],
        currentDay = fromDatetime
    while (currentDay <= toDatetime){
        days.push(new Date (currentDay))
        currentDay = addDays(currentDay, 1)
    }
    return days
}

// Переводит номер дня в неделе в короткий русский вариант 
const weekdayToShortRussian = datetime => ([
    'Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'])[datetime.getDay()]

// Переводит номер дня в неделе в полный русский вариант 
const weekdayToFullRussian = datetime => ([
    'Воскресенье', 'Понедельник', 'Вторник', 'Среда',
    'Четверг', 'Пятница', 'Суббота'])[datetime.getDay()]

const monthToShortRussian = datetime => ([
    'Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь',
    'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'])[datetime.getMonth()]

const monthToFullRussianVersion = datetime => ([
    'Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня',
    'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'])[datetime.getMonth()]

export {
    buildAllEverydaySlots, addDays, buildDays,
    weekdayToShortRussian, weekdayToFullRussian,
    monthToShortRussian, monthToFullRussianVersion
}