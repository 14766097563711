import React, { useState } from 'react'
import PhoneInput from './PhoneInput'

function ClientForm(props){
    let [client, setClient] = useState({
        firstName: '',
        email: '',
        phone: '',
        communicationMethod: 'voice',
        surveyQuestion1: '',
        surveyQuestion2: '',
        surveyQuestion3: '',
        surveyQuestion4: '',
        surveyQuestion5: '',
        surveyQuestion6: '',
    })
    const setClientField = field => event => {
        let newClientData = {...client, [field]: event.target.value}
        setClient(newClientData)
        props.onChangeClient(newClientData)
    }

    return (
        <div>
            <div className="ew-customer-fields mt-8">
                <label className="flex mt-4 aw-text-field is-string has-label" htmlFor="input-firstName">
                    <div className="relative w-full">
                        <span>Имя</span>
                        <input type="string" value={client.firstName} onChange={setClientField("firstName")} required="required" className={"aw-text-field__element p-3 " + (props.formErrors.firstName ? "incorrect-input-value" : "")} id="input-firstName" />
                    </div>
                </label>
                <label className="flex mt-4 aw-text-field is-string has-label" htmlFor="input-email">
                    <div className="relative w-full">
                        <span>Email</span>
                        <input type="string" value={client.email} onChange={setClientField("email")} className={"aw-text-field__element p-3 " + (props.formErrors.email ? "incorrect-input-value" : "")} id="input-email" />
                    </div>
                </label>
                <label className="flex mt-4 aw-text-field is-string has-label" htmlFor="input-phone">
                    <div className="relative w-full">
                        <span>Телефон</span>
                        <PhoneInput id="input-phone" value={client.phone} onChange={setClientField("phone")} invalid={props.formErrors.phone} />
                    </div>
                </label>
                {/* Чекбокс пока не удаляем — может пригодиться его вёрстка, когда попросят добавить оферту какую-нибудь */}
                <div className="aw-switch-field is-checkbox mt-4 fb-field">
                    <input type="checkbox"
                        value="voice"
                        id="voiceCommunicationMethod"
                        className="aw-switch-field__element"
                        checked={client.communicationMethod === "voice"}
                        onChange={setClientField("communicationMethod")} />
                    <label className="aw-switch-field__label" htmlFor="voiceCommunicationMethod">
                        <span>Аудио консультация</span>
                    </label>
                </div>
                <div className="aw-switch-field is-checkbox mt-4 fb-field">
                    <input type="checkbox"
                        value="video"
                        id="videoCommunicationMethod"
                        className="aw-switch-field__element"
                        checked={client.communicationMethod === "video"}
                        onChange={setClientField("communicationMethod")}/>
                    <label className="aw-switch-field__label" htmlFor="videoCommunicationMethod">
                        <span>Видео консультация</span>
                    </label>
                </div>

                <div className="client-survey">
                    <div className="survey-header">Для того, чтобы врач смог оперативно ознакомиться с результатами вашего тестирования, проставьте статусы ниже:</div>

                    <div className="question-text">1. Экспресс-тест STANDARD Q COVID-19 Ag</div>
                    <div className="aw-switch-field is-checkbox mt-4 fb-field question">
                        <input type="checkbox"
                            value="no"
                            id="surveyQuestion1no"
                            className="aw-switch-field__element"
                            checked={client.surveyQuestion1 === "no"}
                            onChange={setClientField("surveyQuestion1")}/>
                        <label className="aw-switch-field__label" htmlFor="surveyQuestion1no">
                            <span>Отрицательный</span>
                        </label>
                    </div>
                    <div className="aw-switch-field is-checkbox mt-4 fb-field question">
                        <input type="checkbox"
                            value="yes"
                            id="surveyQuestion1yes"
                            className="aw-switch-field__element"
                            checked={client.surveyQuestion1 === "yes"}
                            onChange={setClientField("surveyQuestion1")}/>
                        <label className="aw-switch-field__label" htmlFor="surveyQuestion1yes">
                            <span>Положительный</span>
                        </label>
                    </div>

                    <div className="clear"></div>
                    <div className="question-text">2. Тест для выявления респираторно-синицитиального вируса в респираторных выделениях «РЭД»</div>
                    <div className="aw-switch-field is-checkbox mt-4 fb-field question">
                        <input type="checkbox"
                            value="no"
                            id="surveyQuestion2no"
                            className="aw-switch-field__element"
                            checked={client.surveyQuestion2 === "no"}
                            onChange={setClientField("surveyQuestion2")}/>
                        <label className="aw-switch-field__label" htmlFor="surveyQuestion2no">
                            <span>Отрицательный</span>
                        </label>
                    </div>
                    <div className="aw-switch-field is-checkbox mt-4 fb-field question">
                        <input type="checkbox"
                            value="yes"
                            id="surveyQuestion2yes"
                            className="aw-switch-field__element"
                            checked={client.surveyQuestion2 === "yes"}
                            onChange={setClientField("surveyQuestion2")}/>
                        <label className="aw-switch-field__label" htmlFor="surveyQuestion2yes">
                            <span>Положительный</span>
                        </label>
                    </div>

                    <div className="clear"></div>
                    <div className="question-text">3. Тест для выявления вируса гриппа А и В в респираторных выделениях «РЭД»</div>
                    <div className="aw-switch-field is-checkbox mt-4 fb-field question">
                        <input type="checkbox"
                            value="no"
                            id="surveyQuestion3no"
                            className="aw-switch-field__element"
                            checked={client.surveyQuestion3 === "no"}
                            onChange={setClientField("surveyQuestion3")}/>
                        <label className="aw-switch-field__label" htmlFor="surveyQuestion3no">
                            <span>Отрицательный</span>
                        </label>
                    </div>
                    <div className="aw-switch-field is-checkbox mt-4 fb-field question">
                        <input type="checkbox"
                            value="yes"
                            id="surveyQuestion3yes"
                            className="aw-switch-field__element"
                            checked={client.surveyQuestion3 === "yes"}
                            onChange={setClientField("surveyQuestion3")}/>
                        <label className="aw-switch-field__label" htmlFor="surveyQuestion3yes">
                            <span>Положительный</span>
                        </label>
                    </div>

                    <div className="clear"></div>
                    <div className="question-text">4. Тест для выявления респираторного аденовируса в респираторных выделениях «РЭД»</div>
                    <div className="aw-switch-field is-checkbox mt-4 fb-field question">
                        <input type="checkbox"
                            value="no"
                            id="surveyQuestion4no"
                            className="aw-switch-field__element"
                            checked={client.surveyQuestion4 === "no"}
                            onChange={setClientField("surveyQuestion4")}/>
                        <label className="aw-switch-field__label" htmlFor="surveyQuestion4no">
                            <span>Отрицательный</span>
                        </label>
                    </div>
                    <div className="aw-switch-field is-checkbox mt-4 fb-field question">
                        <input type="checkbox"
                            value="yes"
                            id="surveyQuestion4yes"
                            className="aw-switch-field__element"
                            checked={client.surveyQuestion4 === "yes"}
                            onChange={setClientField("surveyQuestion4")}/>
                        <label className="aw-switch-field__label" htmlFor="surveyQuestion4yes">
                            <span>Положительный</span>
                        </label>
                    </div>

                    <div className="clear"></div>
                    <div className="question-text">5. Тест для выявления Стрептококка А в респираторных выделениях «РЭД»</div>
                    <div className="aw-switch-field is-checkbox mt-4 fb-field question">
                        <input type="checkbox"
                            value="no"
                            id="surveyQuestion5no"
                            className="aw-switch-field__element"
                            checked={client.surveyQuestion5 === "no"}
                            onChange={setClientField("surveyQuestion5")}/>
                        <label className="aw-switch-field__label" htmlFor="surveyQuestion5no">
                            <span>Отрицательный</span>
                        </label>
                    </div>
                    <div className="aw-switch-field is-checkbox mt-4 fb-field question">
                        <input type="checkbox"
                            value="yes"
                            id="surveyQuestion5yes"
                            className="aw-switch-field__element"
                            checked={client.surveyQuestion5 === "yes"}
                            onChange={setClientField("surveyQuestion5")}/>
                        <label className="aw-switch-field__label" htmlFor="surveyQuestion5yes">
                            <span>Положительный</span>
                        </label>
                    </div>

                    
                    <div className="clear"></div>
                    <div className="question-text">6. Тест для выявления С-реактивного белка в крови человека></div>
                    <div className="aw-switch-field is-checkbox mt-4 fb-field question">
                        <input type="checkbox"
                            value="no"
                            id="surveyQuestion6no"
                            className="aw-switch-field__element"
                            checked={client.surveyQuestion6 === "no"}
                            onChange={setClientField("surveyQuestion6")}/>
                        <label className="aw-switch-field__label" htmlFor="surveyQuestion6no">
                            <span>Отрицательный</span>
                        </label>
                    </div>
                    <div className="aw-switch-field is-checkbox mt-4 fb-field question">
                        <input type="checkbox"
                            value="yes"
                            id="surveyQuestion6yes"
                            className="aw-switch-field__element"
                            checked={client.surveyQuestion6 === "yes"}
                            onChange={setClientField("surveyQuestion6")}/>
                        <label className="aw-switch-field__label" htmlFor="surveyQuestion6yes">
                            <span>Положительный</span>
                        </label>
                    </div>
                    <div className="clear"></div>

                </div>
            </div>
        </div>
    )
}

export default ClientForm
